import RestApi, { irriSchemeServiceBaseUrl } from '../config/api_config'
export default {
  data () {
      return {
          notificationPaths: [
              '/task-notification/list'
          ]
      }
  },
  computed: {
    hasDropdownLoadedIrrigation () {
      return this.$store.state.IrriConfig.commonObj.hasDropdownLoaded
    }
  },
  watch: {
    hasDropdownLoadedIrrigation: function (newValue) {
      if (!newValue) {
        this.loadDropdownIrrigation()
      }
    }
  },
  created () {
    if (this.hasDropdownLoadedIrrigation && !window.performance) {
      return true
    }
    this.loadDropdownIrrigation()
  },
  methods: {
    loadDropdownIrrigation () {
      RestApi.getData(irriSchemeServiceBaseUrl, '/common-dropdown', null).then(response => {
        if (response.success === true) {
          this.$store.dispatch('IrriConfig/mutateCommonObj', {
            hasDropdownLoaded: true,
            schemeTypeList: response.data.schemeTypeList,
            schemeFromAffidavitList: response.data.schemeFromAffidavitList,
            categoryList: response.data.categoryList,
            subCategoryList: response.data.subCategoryList,
            unitList: response.data.unitList,
            itemList: response.data.itemList,
            pumpType: response.data.pumpType,
            horsePower: response.data.horsePower,
            pumpStock: response.data.pumpStock,
            circleArea: response.data.circleArea,
            projectList: response.data.projectList,
            laboratoryList: response.data.laboratoryList,
            pumpInfoList: response.data.pumpInfoList,
            equipmentTypeList: response.data.equipmentTypeList,
            waterTestingParameterList: response.data.waterTestingParameterList,
            subSchemeTypeList: response.data.subSchemeTypeList,
            pumpCapacityList: response.data.pumpCapacityList,
            mouzaList: response.data.mouzaList
          })
          this.$store.dispatch('IrriConfig/changeCommonObjDropdown', { value: this.$i18n.locale })
        }
      })
    }
  }
}
